import React, { ReactNode, useEffect, useMemo, useState } from 'react'

import useSWR from 'swr'

import { TreatmentEnum, VisibilityEnum } from '@/utils/amplitude/experiment-constants'

import { Bucket } from '../../lib/makeswift/experiments'

type Props = {
	previewBucket?: Bucket
	a?: ReactNode
	b?: ReactNode
	enableAmplitudeAllocation?: boolean
	className?: string
}

export function ExperimentProvider({
	previewBucket,
	a,
	b,
	enableAmplitudeAllocation,
	className
}: Props) {
	// Fetcher function and SWR hook
	const fetcher = (url: string) => fetch(url).then((result) => result.json())
	const { data: experiments, error } = useSWR('/api/amplitude/experiment-provider-data/', fetcher, {
		revalidateOnFocus: false,
		revalidateOnReconnect: false
	})
	// Initial states for visibility preventing LCP issues
	const [displayValidator, setDisplayValidator] = useState<Bucket>(Bucket.A)
	const [bucketVisibility, setBucketVisibility] = useState({
		a: VisibilityEnum.Invisible,
		b: VisibilityEnum.Invisible
	})
	const [isMakeswiftBuilder, setIsMakeswiftBuilder] = useState<boolean>(false)

	useEffect(() => {
		if (!experiments && !error) {
			return
		}

		if (error) {
			console.error('Error fetching data', error)
			return
		}

		if (experiments?.isMakeswiftBuilder) {
			setIsMakeswiftBuilder(true)
			return
		}

		let newBucketVisibility = {
			a: VisibilityEnum.Visible,
			b: VisibilityEnum.Invisible
		}

		let newDisplayValidator: Bucket = Bucket.A
		experiments?.forEach((experiment: { variant: string; flagKey: string }) => {
			if (experiment.variant === TreatmentEnum.TREATMENT) {
				newDisplayValidator = Bucket.B
				newBucketVisibility.a = VisibilityEnum.Invisible
				newBucketVisibility.b = VisibilityEnum.Visible
			}
		})

		setDisplayValidator(newDisplayValidator)
		setBucketVisibility(newBucketVisibility)
	}, [error, experiments])

	// Determine the bucket to display based on allocation settings
	// Makeswift builder can't get allocation values and it displays preview by default
	const preview = useMemo(() => {
		return enableAmplitudeAllocation && !isMakeswiftBuilder ? displayValidator : previewBucket
	}, [enableAmplitudeAllocation, isMakeswiftBuilder, displayValidator, previewBucket])

	// Render the appropriate bucket content
	const content = useMemo(() => {
		if (enableAmplitudeAllocation && !isMakeswiftBuilder) {
			return (
				<div className={bucketVisibility[preview === 'a' ? 'a' : 'b']}>
					{preview === Bucket.A ? a : b}
				</div>
			)
		}

		return <div className={VisibilityEnum.Visible}>{preview === Bucket.A ? a : b}</div>
	}, [a, b, bucketVisibility, enableAmplitudeAllocation, isMakeswiftBuilder, preview])

	return (
		<div data-testid="experiment-provider" className={className}>
			{content}
		</div>
	)
}
